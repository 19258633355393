import * as React from "react";

import ServiceTemplate from "src/components/Templates/2T-serviceTemplates/serviceTemplate";

import {
  // openServHeroProps,
  // openServList,
  mainUpkpHeroProps,
  mainUpkpServList,
  // expandBuzHeroProps,
  // expandBuzServList,
  // visaServHeroProps,
  // visaServList,
  // chinaHeroProps,
  // chinaServList,
  // leaveHeroProps,
  // leaveServList
} from "src/data/servicesList/data";



// export default ({ location }:any) => {
export default () => {
  // console.log(location);
  return <ServiceTemplate
    hero={mainUpkpHeroProps}
    serviceList={mainUpkpServList}
    lgString={"col-lg-3"}
  />
  ;
}